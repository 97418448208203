<template>
  <div>
    <div
      class="o-order-step o-order-step-payment"
      :class="{'o-order-step--filled': isFilled && !edited}"
    >
      <SfHeading
        :title="$t('Payment method')"
        :level="2"
        class="o-order-step__title o-order-step__title--payment"
        :class="{'o-order-step__title--default': isDefault}"
      >
        <template v-if="isFilled && !edited" #subtitle>
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit">
            <SfButton
              @click="edit"
              data-transaction-name="Checkout - Step Shipping - Edit"
              class="sf-button--pure o-order-step__btn--edit"
            >
              {{ $t('Edit order') }}
            </SfButton>
          </div>
        </template>
      </SfHeading>
      <ASmoothReflow class="o-order-step__content o-order-step-payment__content">
        <div v-if="isPromo" class="o-order-step-content__row">
          <div class="o-order-step-payment__promo">
            <svg width="30" height="20" viewBox="0 0 30 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="30" height="20" rx="2" fill="#151515"/>
              <path d="M25 9.93026C25 13.3466 22.2361 16.1105 18.8198 16.1105C17.3777 16.1105 16.0558 15.6127 15.0086 14.7886C16.4507 13.6556 17.3605 11.9045 17.3605 9.93026C17.3605 7.95601 16.4335 6.20494 15.0086 5.07189C16.0558 4.24786 17.3777 3.75 18.8198 3.75C22.2361 3.75 25 6.53112 25 9.93026Z" fill="#F79E1B"/>
              <path d="M15.0086 5.07189C15.0086 5.07189 15.0086 5.07189 15.0086 5.07189C16.4335 6.20494 17.3605 7.95601 17.3605 9.93026C17.3605 11.9045 16.4507 13.6556 15.0086 14.7886L14.9914 14.7886C13.5665 13.6727 12.6395 11.9045 12.6395 9.93029C12.6395 7.95605 13.5665 6.20497 14.9914 5.07193C14.9914 5.07191 14.9914 5.07194 14.9914 5.07193L15.0086 5.07189Z" fill="#FF5F00"/>
              <path d="M12.6395 9.93029C12.6395 7.95605 13.5665 6.20497 14.9914 5.07193C13.9442 4.24789 12.6223 3.75003 11.1803 3.75003C7.76395 3.75003 5 6.51398 5 9.93029C5 13.3466 7.76395 16.1105 11.1803 16.1105C12.6223 16.1105 13.9442 15.6127 14.9914 14.7886C13.5665 13.6727 12.6395 11.9045 12.6395 9.93029Z" fill="#EB001B"/>
            </svg>

            <span>
              {{ $t('Pay by mastercard') }}
            </span>
          </div>
        </div>

        <template v-if="edited && paymentMethods.length">
          <div class="radio-switcher-simple">
            <template v-for="method in paymentMethodsList">
              <SfRadio
                v-model="paymentMethodGroup"
                :key="`${method.code}-radio`"
                :value="method.code"
                class="radio-switcher-simple__input"
                @input="setType"
              >
                <template #label>
                  <div class="sf-radio__label radio-switcher-square__label">
                    <div>{{ $t(method.title) }}</div>
                  </div>
                </template>
              </SfRadio>

              <ASmoothReflow :key="`${method.code}-block`">
                <div v-if="paymentMethodGroup === method.code">
                  <div
                    v-if="message"
                    class="o-order-step-content__row o-order-step-content__message"
                    :class="{'o-order-step-payment__no-margin-bottom': paymentMethodGroup !== 'NOVA_POST_CASH_ON_DELIVERY'}"
                    v-html="$t(message)"
                  ></div>

                  <template v-if="paymentMethodGroup === 'CARD_ONLINE' && isLoggedIn">
                    <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-payment__no-margin-top">
                      <MOrderStepPaymentCard
                        class="o-order-step-content__card"
                        @cardSelected="setSelectedCard"
                        :pre-selected-card-mask="String(selectedCardMask)"
                      />
                    </div>
                  </template>

                  <template v-if="paymentMethodGroup === 'CASH_CARD' && paymentMethodsGroup.length">
                    <MPaymentSelect
                      :items="paymentMethodsGroup"
                      :selected="payment.paymentMethod"
                      @set-item="setType"
                    />
                  </template>

                  <template v-if="paymentMethodGroup === 'NOVA_POST_CASH_ON_DELIVERY'">
                    <ACheckoutMessage
                      :message="$t('STEP_PAYMENT_CASH_NOVA_WARNING')"
                      :variant="'warning'"
                    />
                  </template>
                </div>
              </ASmoothReflow>
            </template>
          </div>

          <div class="o-order-step-payment__tips-block">
            <div v-if="getTips.length" class="o-order-step-payment__tips">
              <svg width="100%" height="2" viewBox="0 0 1200 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H1200" stroke="#C2C6D0" stroke-linecap="round" stroke-linejoin="round" vector-effect="non-scaling-stroke" stroke-dasharray="5 5"/>
              </svg>

              <div class="o-order-step-payment__tips-header">{{ $t('Tips to the collector title') }}</div>

              <div class="radio-switcher-square">
                <SfRadio
                  v-for="tip in getTips"
                  :key="tip.percent"
                  :selected="currentTips"
                  :value="`${tip.percent}`"
                  name="shipping-method"
                  class="radio-switcher-square__input"
                  @input="setPaymentTips"
                >
                  <template #label>
                    <div class="sf-radio__label radio-switcher-square__label o-order-step-payment__tips-item">
                      <div v-if="tip.percent" class="o-order-step-payment__tips-item-percent">{{tip.percent}}%</div>
                      <div class="o-order-step-payment__tips-item-price">{{tip.value}} грн</div>
                    </div>
                  </template>
                </SfRadio>
              </div>
            </div>

            <div class="o-order-step-payment__tips-input-wrapper">
              <SfInput
                v-model="payment.currentTipsAmount"
                type="text"
                pattern="^[0-9]+$"
                name="currentTipsAmount"
                class="sf-input--filled o-order-step-payment__tips-input"
                :label="$t('Amount label')"
                inputmode="numeric"
                data-transaction-name="Current - Tips"
                :valid="!$v.payment.currentTipsAmount.$error"
                :error-message="$t('Only whole numbers are allowed')"
                @input.native="checkTips"
                @blur="checkTips"
              />
            </div>
          </div>

          <ASmoothReflow>
            <div
              v-if="showError"
              class="o-order-step-content__error o-order-step-content__top-m-10">
              {{ $t('Please select a payment method') }}
            </div>
          </ASmoothReflow>

          <div class="o-order-step-content__row o-order-step-content__top-m-24">
            <SfButton
              data-transaction-name="Checkout - Payment - Save"
              @click="onSaveButtonClick"
              class="sf-button--primary sf-button--full-width-mobile"
            >
              <template v-if="isFilled">
                {{ $t("Save changes") }}
              </template>
              <template v-else>
                {{ $t("Continue") }}
              </template>
            </SfButton>
          </div>
        </template>
        <template v-if="isFilled && !edited">
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit">
            <div class="o-order-step-payment__card">
              <span>{{ subtitle }}</span>
              <span v-if="selectedCardMask && selectedCardType && payment.paymentMethod === 'CARD_ONLINE'" class="o-order-step-payment__card-content">
                {{selectedCardMask}}
                <APaymentIcon :type="selectedCardType" />
              </span>
            </div>
          </div>
        </template>
      </ASmoothReflow>
    </div>

    <ACheckoutMessage
      v-if="shouldShowMessage"
      :message="messageText"
      :variant="messageVariant"
    />
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import {
  SfHeading,
  SfButton,
  SfRadio
} from '@storefront-ui/vue'
import MOrderStepPaymentCard from 'theme/components/molecules/m-order-step-payment-card';
import GoogleTagManager from 'theme/mixins/gtm'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import APaymentIcon from 'theme/components/atoms/a-payment-icon';
import SfInput from 'theme/components/storefront-override/SfInput.vue';
import MPaymentSelect from 'theme/components/molecules/m-payment-select.vue';
import ACheckoutMessage from 'theme/components/atoms/a-checkout-message.vue';
import { isPriceValid } from 'theme/helpers/validation';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'OOrderStepPayment',
  components: {
    SfInput,
    ACheckoutMessage,
    MPaymentSelect,
    APaymentIcon,
    SfRadio,
    ASmoothReflow,
    MOrderStepPaymentCard,
    SfHeading,
    SfButton
  },
  props: {
    paymentMethods: {
      required: true,
      type: Array,
      default: () => []
    },
    getPaymentMethods: {
      required: true,
      type: Array,
      default: () => []
    },
    availablePaymentMethods: {
      required: true,
      type: Array,
      default: () => []
    },
    shippingDetails: {
      required: true,
      type: Object,
      default: () => ({})
    },
    getActiveStep: {
      required: true,
      type: String,
      default: () => ''
    },
    getShops: {
      required: true,
      type: Array,
      default: () => []
    },
    getTips: {
      required: true,
      type: Array,
      default: () => []
    },
    getCurrentTimeSlot: {
      required: true,
      type: Object,
      default: () => ({})
    },
    isLoggedIn: {
      required: true,
      type: Boolean,
      default: () => false
    }
  },
  mixins: [GoogleTagManager],
  data () {
    return {
      isPromo: false,
      showError: false,
      paymentMethodGroup: '',
      currentTips: '0',
      payment: {
        paymentMethod: '',
        currentTipsAmount: '',
        paymentMethodAdditional: { card: {} },
        paymentTips: null
      },
      isFilled: false
    }
  },
  validations () {
    return {
      payment: {
        paymentMethod: {
          required
        },
        currentTipsAmount: {
          isPriceValid
        }
      }
    }
  },
  computed: {
    ...mapState({
      isPriceChanged: state => state.cart.isPriceChanged
    }),
    paymentMethodsPure () {
      const paymentMethods = [...this.availablePaymentMethods].filter(k => !['DELIVERY_CASH', 'DELIVERY_CARD'].includes(k))

      const cashCardGroups = this.availablePaymentMethods.filter(k => ['DELIVERY_CASH', 'DELIVERY_CARD'].includes(k))

      if (cashCardGroups.length && !paymentMethods.includes('CASH_CARD')) paymentMethods.push('CASH_CARD')

      return paymentMethods
    },
    paymentMethodsList () {
      return this.getPaymentMethods
        .filter(method => this.paymentMethodsPure.includes(method.code))
        .sort((a, b) => a.position - b.position)
    },
    paymentMethodsGroup () {
      const paymentMethods = [...this.availablePaymentMethods].filter(k => ['DELIVERY_CASH', 'DELIVERY_CARD'].includes(k))

      return this.getPaymentMethods
        .filter(method => paymentMethods.includes(method.code))
        .sort((a, b) => a.position - b.position)
    },
    subtitle () {
      if (!this.isFilled) return '';

      const method = this.paymentMethods.find(method => method.code === this.payment.paymentMethod);

      return method ? this.$t(method.title) : '';
    },
    message () {
      const method = this.paymentMethods.find(method => method.code === this.payment.paymentMethod);
      return method?.message || '';
    },
    edited () {
      return this.getActiveStep === 'payment'
    },
    isDefault () {
      return !this.edited && !this.isFilled
    },
    selectedCardType () {
      return this.payment.paymentMethodAdditional?.card?.card_type || ''
    },
    selectedCardMask () {
      return this.payment.paymentMethodAdditional?.card?.card_mask || ''
    },
    currentTipsAmount () {
      if (!this.payment.paymentTips) return null

      return this.getTips.find(i => i.percent === this.payment.paymentTips?.percent)
    },
    shouldShowMessage () {
      return this.getMessageConfig?.show || false
    },
    messageText () {
      const config = this.getMessageConfig
      return config ? this.$t(config.message) : ''
    },
    messageVariant () {
      return this.getMessageConfig?.variant || 'warning'
    },
    getMessageConfig () {
      if (this.paymentMethodGroup === 'CARD_ONLINE' && !this.selectedCardMask && this.isFilled && !this.edited) {
        return {
          show: true,
          message: this.$t('You will be sent an SMS after the assembly is complete.')
        }
      }

      if (this.paymentMethodGroup === 'NOVA_POST_CASH_ON_DELIVERY' && this.isFilled && !this.edited) {
        return {
          show: true,
          message: this.$t('STEP_PAYMENT_CASH_NOVA_WARNING')
        }
      }

      return null
    }
  },
  watch: {
    edited (val) {
      if (!val) return

      if (!this.payment.currentTipsAmount || this.payment.paymentTips) return

      const tips = this.payment.currentTipsAmount

      this.payment.paymentTips = this.getTips.find(i => i.value === +tips)
      this.currentTips = this.payment.paymentTips?.percent?.toString() || null
    },
    currentTipsAmount: {
      immediate: true,
      handler: function (val) {
        if (!val?.value) return

        this.payment.currentTipsAmount = val?.value
      }
    }
  },
  methods: {
    ...mapActions({
      setPriceChanging: 'cart/setPriceChanging'
    }),
    checkTips (e) {
      this.$v.payment.currentTipsAmount.$touch()

      const val = e?.target?.value

      if (!(+val)) {
        this.currentTips = '0'
        this.payment.paymentTips = null
        return
      }

      this.currentTips = this.getTips.find(i => i.value === +val)?.percent?.toString() || null
      this.payment.paymentTips = this.getTips.find(i => i.value === +val)?.percent?.toString() || null
    },
    setPaymentTips (tipsPercent) {
      this.payment.paymentTips = this.getTips.find(i => i.percent === +tipsPercent) || null
      this.payment.currentTipsAmount = this.payment.paymentTips?.value || ''
      this.currentTips = `${this.payment.paymentTips?.percent || '0'}`
    },
    sendDataToCheckout (onCheckoutLoad = false) {
      this.isFilled = true
      this.$bus.$emit('checkout-after-paymentDetails', {
        data: this.payment,
        isPriceChanged: this.isPriceChanged,
        onCheckoutLoad
      }, !this.$v.payment.$invalid)
    },
    onSaveButtonClick () {
      if (this.$v.payment.paymentMethod.$invalid) {
        this.showError = true
        return
      }

      if (this.$v.payment.$invalid) return;

      this.setPriceChanging(false)

      this.sendDataToCheckout()
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'payment')
    },
    setSelectedCard (value) {
      this.payment.paymentMethodAdditional.card = value
    },
    onCheckoutLoad () {
      const sessionData = getCheckoutSessionItemByKey('paymentDetails')

      if (!sessionData) return

      this.payment = sessionData
      this.paymentMethodGroup = ['DELIVERY_CASH', 'DELIVERY_CARD'].includes(sessionData?.paymentMethod)
        ? 'CASH_CARD' : sessionData?.paymentMethod

      this.currentTips = `${this.payment.paymentTips?.percent || '0'}`

      if (!this.paymentMethodsPure.includes(sessionData?.paymentMethod)) {
        return
      }

      if (!this.$v.payment.$invalid) {
        this.sendDataToCheckout(true)
      }
    },
    resetCheckout () {
      this.paymentMethodGroup = ''

      this.payment = {
        paymentMethod: '',
        paymentMethodAdditional: {
          card: {}
        },
        paymentTips: null
      }

      this.currentTips = '0'

      this.isFilled = false
    },
    setType (item) {
      if (item === 'CASH_CARD' && this.paymentMethodsGroup.length === 1) {
        this.setGroupType(this.paymentMethodsGroup[0]?.code || '')

        return
      }

      this.setGroupType(item === 'CASH_CARD' && this.paymentMethodsGroup.length ? '' : item)
    },
    setGroupType (item) {
      this.showError = false

      this.payment.paymentMethod = item
    }
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('user-after-logout', this.resetCheckout)
    this.$bus.$on('timeslot-dropped', this.resetCheckout)
    this.$bus.$on('payment-dropped', this.resetCheckout)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('user-after-logout', this.resetCheckout)
    this.$bus.$off('timeslot-dropped', this.resetCheckout)
    this.$bus.$off('payment-dropped', this.resetCheckout)
  }
};
</script>

<style lang="scss" scoped>
@import "~theme/css/components/organisms/o-order-step";

.o-order-step-payment {
  &__tips-link {
    margin-top: 5px;
    padding: 0;
    background: none;
    color: var(--orange);
    font-size: var(--font-size-14);
    border: none;
    cursor: pointer;
  }

  &__tips-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__tips-input-wrapper {
    width: 100%;
  }

  &__tips-input {
    ::v-deep {
      .sf-input__wrapper {
        margin: 0;
      }

      .sf-input__error-message {
        height: auto;
        line-height: calc(var(--font-xs) * 1.2);
      }
    }
  }

  &__tips-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    height: 100%;
    gap: 2px;

    &-percent {
      font-size: var(--font-sm);
      opacity: .5;
    }

    &-price {
      font-size: var(--font-size-16);
    }
  }

  &__tips {
    margin-top: var(--spacer-20);
    display: flex;
    flex-direction: column;
    width: 100%;

    &-header {
      padding-top: var(--spacer-15);
      padding-bottom: var(--spacer-10);
      font-size: var(--font-size-16);
      font-weight: var(--font-medium);
    }

    ::v-deep {
      .sf-radio__container {
        padding: 5px;
      }
    }
  }

  .radio-switcher-simple {
    padding-bottom: 0;
  }

  &__promo {
    padding: var(--spacer-8);
    background: var(--light-gray);
    display: flex;
    gap: var(--spacer-8);
    justify-content: flex-start;
    align-items: center;
    margin-top: 0!important;
  }

  &__no-margin-top {
    margin-top: 0!important;
  }

  &__no-margin-bottom {
    margin-bottom: 0!important;
  }

  &__card {
    display: flex;
    align-items: center;
    gap: var(--spacer-24);

    &-content {
      color: #000;
      display: inline-flex;
      align-items: center;
      gap: var(--spacer-8);

      > * {
        display: inline-flex;
        align-items: center;
      }
    }
  }

  .o-order-step {
    &-content__row {
      margin-top: 5px;
    }

    &-content__card {
      margin-top: var(--spacer-16);
      width: 100%;
    }

    &-content__message {
      flex-direction: column;
      color: var(--dark-gray);
    }

    &__select-flex {
      display: flex;
      align-items: center;

      &_icon {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
        width: 17px;
      }

      &_text {
        display: block;
        overflow: hidden;
        flex: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
</style>
