<template>
  <div>
    <div
      class="o-order-step o-order-step-time-slots"
      :class="{'o-order-step--filled': isFilled && !edited}"
    >
      <SfHeading
        :title="blockTitle"
        :level="2"
        class="o-order-step__title o-order-step__title--time-slots"
        :class="{'o-order-step__title--default': isDefault}"
      >
        <template v-if="isFilled && !edited" #subtitle>
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit">
            <SfButton
              @click="edit"
              data-transaction-name="Checkout - Step Package - Edit"
              class="sf-button--pure o-order-step__btn--edit"
            >
              {{ $t('Edit order') }}
            </SfButton>
          </div>
        </template>
      </SfHeading>

      <ASmoothReflow>
        <div
          v-if="isLoading && !isDefault"
          class="o-order-step__content"
        >
          <div class="o-order-step-content__row o-order-step-time-slots__loader">
            <SfLoader :loading="true" />
          </div>
        </div>
        <template v-else>
          <template v-if="edited && shippingTimeSlots.length">
            <MTimeslotForm
              :current="timeSlot"
              :items="shippingTimeSlots"
              :show-reset-message="showResetMessage"
              :block-title="blockTitle"
              :is-filled="isFilled"
              :is-delivery="isDelivery"
              @set-timeslot="setTimeslot"
              @submit="submit"
            />
          </template>
          <div v-else-if="edited && !shippingTimeSlots.length" class="o-order-step__content">
            <div class="o-order-step-content__row o-order-step-content__error o-order-step-content__end-m-24">
              {{timeSlotsInfoMessage}}
            </div>

            <SfButton
              data-transaction-name="Checkout - TimeSlots - Change Shipping"
              @click="openShippingModal()"
              class="sf-button--primary sf-button--full-width-mobile form__action-button"
            >
              {{ $t('Change shipping method') }}
            </SfButton>
          </div>
        </template>

        <div
          v-if="isFilled && !edited"
          class="o-order-step__content"
        >
          <div class="o-order-step-content__row short-description">
            <div class="short-description__value">
              <span
                v-if="isCurrentPriority"
                class="o-order-step-time-slots__priority"
              />

              <span>{{ `${currentTimeSlotString}` }}</span>
            </div>
          </div>
        </div>
      </ASmoothReflow>
    </div>
  </div>
</template>

<script>
import {
  SfButton,
  SfHeading
} from '@storefront-ui/vue'
import { mapGetters, mapActions } from 'vuex'
import SfLoader from 'src/themes/varus/components/storefront-override/SfLoader'
import GoogleTagManager from 'theme/mixins/gtm'
import { required } from 'vuelidate/lib/validators'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import Delivery from 'theme/mixins/Delivery';
import MTimeslotForm from 'theme/components/molecules/m-timeslot-form.vue';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import { date } from '@vue-storefront/core/filters';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import { deliveryMethods } from '$modules/shipping/config';
import { shipping } from 'config';

export default {
  name: 'OOrderStepTimeSlots',
  components: {
    MTimeslotForm,
    ASmoothReflow,
    SfButton,
    SfHeading,
    SfLoader
  },
  mixins: [GoogleTagManager, Delivery],
  filters: {
    date
  },
  data () {
    return {
      timeSlot: null,
      isLoading: false,
      isFilled: false
    }
  },
  validations: {
    timeSlot: {
      id: {
        required
      },
      date: {
        required
      }
    }
  },
  props: {
    shippingDetails: {
      required: true,
      type: Object,
      default: () => ({})
    },
    getActiveStep: {
      required: true,
      type: String,
      default: () => ''
    },
    isDeliveryFilled: {
      type: Boolean,
      default: false
    },
    getCurrentTimeSlot: {
      required: true,
      type: Object,
      default: () => ({})
    },
    totals: {
      required: true,
      type: Array,
      default: () => []
    },
    shippingTimeSlots: {
      required: true,
      type: Array,
      default: () => []
    },
    cartStates: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  beforeMount () {
    this.$bus.$on('after-updating-current-time-slot', this.onAfterUpdatingCurrentTimeSlot)
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('user-after-logout', this.resetCheckout)
    this.$bus.$on('timeslot-dropped', this.resetCheckout)
  },
  beforeDestroy () {
    this.$bus.$off('after-updating-current-time-slot', this.onAfterUpdatingCurrentTimeSlot)
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('user-after-logout', this.resetCheckout)
    this.$bus.$off('timeslot-dropped', this.resetCheckout)
  },
  computed: {
    ...mapGetters({
      shippingDetails: 'shipping/getShippingDetails',
      productsInCart: 'cart/getCartItems',
      getCartToken: 'cart/getCartToken'
    }),
    totalValues () {
      return this.totals.reduce((result, price) => {
        result[price.code] = price.value;
        return result;
      }, {});
    },
    timeslotDate () {
      if (!this.timeSlot?.date) return ''

      const [day, month, year] = this.timeSlot.date.split('-')
      const dateStr = `${year}-${month}-${day}`

      return date(dateStr, 'DD MMM, ddd')
    },
    isCurrentPriority () {
      return this.timeSlot?.delivery_method?.toUpperCase() === shipping.codes.deliveryPriority
    },
    currentTimeSlotString () {
      const result = []

      const price = this.totalValues?.shipping || this.timeSlot?.price

      this.timeslotDate && result.push(this.timeslotDate)
      this.timeSlot?.value && result.push(this.timeSlot?.value)

      price && result.push(
        this.timeSlot?.free_shipping ? this.$t('free delivery') : `${price}грн`
      )

      return result.join(', ')
    },
    edited () {
      return this.getActiveStep === 'timeSlots'
    },
    isDefault () {
      return !this.edited && !this.isFilled
    },
    timeSlotsInfoMessage () {
      return this.$t('This store does not have delivery windows available. Please try another shipping method or address.')
    },
    isDelivery () {
      return deliveryMethods.includes(this.shippingDetails?.shippingMethod?.toUpperCase())
    },
    blockTitle () {
      return this.$t(!this.isDelivery ? 'Time of pickup' : 'Time of delivery')
    },
    showResetMessage () {
      return !this.cartStates?.shippingTimeSlots?.valid || false
    }
  },
  methods: {
    ...mapActions({
      loadShippingTimeSlots: 'checkout/loadShippingTimeSlots',
      setCurrentTimeSlot: 'checkout/setCurrentTimeSlot'
    }),
    sendDataToCheckout (onCheckoutLoad = false) {
      this.isFilled = true
      this.$bus.$emit('checkout-after-timeSlots', { onCheckoutLoad })
    },
    onSaveButtonClick () {
      this.sendDataToCheckout()
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'timeSlots')
    },
    async loadTimeSlots (current) {
      try {
        this.isLoading = true
        const sku = await this.loadShippingTimeSlots(current)

        if (sku) {
          this.$emit('set-sku', sku)
        }
      } catch (e) {
        sendToLogs(
          groups.Timeslots,
          'timeslot:checkout:loadTimeSlots:error',
          { message: e?.message }
        )
      } finally {
        this.isLoading = isNaN(this.getCartToken)
      }
    },
    onAfterUpdatingCurrentTimeSlot () {
      this.timeSlot = this.getCurrentTimeSlot
      this.isFilled = true
      this.isLoading = false
    },
    async onCheckoutLoad () {
      const sessionData = getCheckoutSessionItemByKey('currentTimeSlot')

      if (!sessionData) return

      await this.loadTimeSlots(sessionData)
      this.timeSlot = sessionData

      if (!this.cartStates?.shippingTimeSlots?.valid) {
        this.setCurrentTimeSlot(null)

        return
      }

      this.setCurrentTimeSlot(this.timeSlot)

      if (this.timeSlot?.id) this.sendDataToCheckout(true)
    },
    resetCheckout () {
      this.isFilled = false
      this.timeSlot = null
    },
    setTimeslot (item) {
      this.$store.dispatch('checkout/clearTimeSlotsError')
      this.timeSlot = item
    },
    submit () {
      this.setCurrentTimeSlot(this.timeSlot)

      if (this.timeSlot?.id !== this.getCurrentTimeSlot?.id) {
        this.$bus.$emit('payment-has-dropped')
      }

      this.onSaveButtonClick()
    }
  },
  watch: {
    edited: {
      handler: async function (value) {
        if (!value || this.isLoading) return

        await this.loadTimeSlots()

        if (!this.getCurrentTimeSlot?.id && !this.timeSlot?.id) return

        if (!this.getCurrentTimeSlot?.id) {
          this.timeSlot = null
        }
      },
      immediate: true
    },
    getCartToken: {
      handler: function (val, oldVal) {
        if (this.edited && !isNaN(val) && isNaN(oldVal)) {
          this.loadTimeSlots()
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-order-step";

.special-product-notification {
  background: var(--yellow-corn-silk);
  padding: var(--spacer-10);
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-14);
  font-weight: var(--font-normal);
  box-sizing: border-box;

  &:before {
    content: '';
    display: block;
    position: relative;
    min-width: var(--spacer-18);
    min-height: var(--spacer-18);
    margin-right: var(--spacer-10);
    background: url('~theme/assets/info.svg') 0 0 no-repeat;
    background-size: contain;
  }
}

.short-description {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  &__value {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--black);
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;
    text-transform: capitalize;

    span {
      white-space: nowrap;
    }
  }
}

.m-info-block {
  --info-block-background: rgba(235, 103, 71, 0.1);
}

.o-order-step__select {
  ::v-deep .sf-select__options {
    scrollbar-width: thin;
    scrollbar-color: var(--orange) var(--gray20);;

    &::-webkit-scrollbar-thumb {
      background-color: var(--orange);
    }
  }
}

.o-order-step-time-slots {
  flex-direction: column;

  .o-order-step__content {
    max-width: initial;
  }

  .short-description__value {
    display: flex;
    align-items: center;
    gap: var(--spacer-2);
  }

  &__priority {
    color: var(--orange);
    font-size: var(--font-size-16);
    @include font-icon(var(--icon-fire-2));
  }

  &__loader {
    height: 38px;
  }
}

</style>
