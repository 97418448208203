<template>
  <div>
    <div
      id="o-order-step-shipping"
      class="o-order-step o-order-step-shipping"
      :class="{'o-order-step--filled': isFilled && !edited}"
    >
      <SfHeading
        id="o-order-step__title--shipping"
        :title="$t('Shipping method')"
        :level="2"
        class="o-order-step__title o-order-step__title--shipping"
        :class="{'o-order-step__title--default': isDefault}"
      >
        <template v-if="isFilled && !edited" #subtitle>
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-content__row--edit">
            <SfButton
              @click="edit"
              data-transaction-name="Checkout - Step Shipping - Edit"
              class="sf-button--pure o-order-step__btn--edit"
            >
              {{ $t('Edit order') }}
            </SfButton>
          </div>
        </template>
      </SfHeading>

      <ASmoothReflow class="o-order-step__content o-order-step-shipping__content" :class="{
      'o-order-step-shipping--full-width': isFilled && !edited
    }">
        <template v-if="edited && shipping.method">
          <div
            v-if="shippingString.address"
            class="o-order-step-content__row o-order-step-shipping__current"
          >
            <div class="o-order-step-shipping__label">{{ shippingString.type }}:</div>

            {{ shippingString.address }}
          </div>
          <div class="o-order-step-content__row o-order-step-content__row--align-left o-order-step-shipping--edit">
            <SfButton
              data-transaction-name="Checkout - Shipping - Modal"
              @click="openModal"
              class="sf-button--pure o-order-step__btn--edit"
            >
              {{ $t('Change shipping method') }}
            </SfButton>
          </div>
          <template v-if="isDeliveryShippingMethod">
            <div class="o-order-step-content__row">
              <div class="radio-switcher-square o-order-step__select">
                <SfRadio
                  v-for="type in premisesTypes"
                  :key="type.code"
                  :value="type.code"
                  v-model="shippingAddress.premisesType"
                  name="sort-options"
                  class="radio-switcher-square__input"
                >
                  <template #label>
                    <div class="sf-radio__label radio-switcher-square__label">
                      <div>{{ $t(type.title) }}</div>
                    </div>
                  </template>
                </SfRadio>
              </div>
            </div>
            <template v-if="shippingAddress.premisesType === 'FLAT'">
              <div class="o-order-step-content__row">
                <SfInput
                  key="entranceNumber"
                  name="entranceNumber"
                  :label="$t('Entrance number')"
                  class="sf-input--filled o-order-step__input--first-element"
                  v-model.trim="shippingAddress.entranceNumber"
                  @blur="$v.shippingAddress.entranceNumber.$touch()"
                  :valid="isValidEntranceNumber"
                  :required="true"
                  :error-message="getErrorMessage('entranceNumber')"
                />
                <SfInput
                  key="flour"
                  name="flour"
                  :label="$t('Flour')"
                  class="sf-input--filled"
                  v-model.trim="shippingAddress.flour"
                  @blur="$v.shippingAddress.flour.$touch()"
                  :required="true"
                  :valid="isValidFlour"
                  :error-message="getErrorMessage('flour')"
                />
              </div>
              <div class="o-order-step-content__row">
                <SfInput
                  key="doorPostalHouseNumber"
                  name="doorPostalHouseNumber"
                  :label="$t('Apartment')"
                  class="sf-input--filled o-order-step__input--first-element"
                  :required="true"
                  v-model.trim="shippingAddress.doorPostalHouseNumber"
                  @blur="$v.shippingAddress.doorPostalHouseNumber.$touch()"
                  :valid="isValidDoorPostalHouseNumber"
                  :error-message="getErrorMessage('doorPostalHouseNumber')"
                />
                <SfInput
                  key="intercomCode"
                  name="intercomCode"
                  :label="$t('Intercom code')"
                  class="sf-input--filled"
                  v-model.trim="shippingAddress.intercomCode"
                  @blur="$v.shippingAddress.intercomCode.$touch()"
                  :valid="$v.shippingAddress.intercomCode.isValidStringCharacter"
                  :error-message="getErrorMessage('intercomCode')"
                />
              </div>
            </template>
            <template v-if="shippingAddress.premisesType === 'OFFICE'">
              <div class="o-order-step-content__row">
                <SfInput
                  key="houseBuilding"
                  name="houseBuilding"
                  :label="$t('House building')"
                  class="sf-input--filled o-order-step__input--first-element"
                  v-model.trim="shippingAddress.houseBuilding"
                  @blur="$v.shippingAddress.houseBuilding.$touch()"
                  :valid="isValidHouseBuilding"
                  :error-message="getErrorMessage('houseBuilding')"
                />
                <SfInput
                  key="officeNumber"
                  name="officeNumber"
                  :label="$t('Office number')"
                  class="sf-input--filled"
                  v-model.trim="shippingAddress.officeNumber"
                  @blur="$v.shippingAddress.officeNumber.$touch()"
                  :valid="isValidOfficeNumber"
                  :error-message="getErrorMessage('officeNumber')"
                />
              </div>
              <div class="o-order-step-content__row">
                <SfInput
                  key="companyName"
                  name="companyName"
                  :label="$t('Company name')"
                  class="sf-input--filled o-order-step__input--width-100"
                  v-model.trim="shippingAddress.companyName"
                  @blur="$v.shippingAddress.companyName.$touch()"
                  :required="true"
                  :valid="isValidCompanyName"
                  :error-message="getErrorMessage('companyName')"
                />
              </div>
            </template>
          </template>
          <div
            v-if="!isNewPost"
            class="o-order-step-content__row o-order-step-content__row--align-left row__recipient"
            :class="{'row__recipient--true': shippingAddress.isOtherRecipient}"
          >
            <SfCheckbox
              class="o-order-step-shipping__checkbox"
              v-model="shippingAddress.isOtherRecipient"
              :label="$t('Recipient is not me')"
            />
          </div>
          <template v-if="!isNewPost && shippingAddress.isOtherRecipient">
            <div class="o-order-step-content__row">
              <SfInput
                key="phone"
                name="phone"
                v-model="shippingAddress.phone"
                @blur="$v.shippingAddress.phone.$touch()"
                :required="true"
                :valid="isValidPhone"
                :error-message="!$v.shippingAddress.phone.required
                ? $t('Field is required')
                : $t('Please provide valid phone number')
              "
                v-mask="'+38##########'"
                :label="$t('Phone of recipient')"
                class="sf-input--filled o-order-step__input--width-100"
              />
            </div>
            <div class="o-order-step-content__row">
              <SfInput
                key="otherRecipientName"
                name="otherRecipientName"
                :label="$t('Name of recipient')"
                class="sf-input--filled o-order-step__input--width-100"
                v-model.trim="shippingAddress.otherRecipientName"
                @blur="$v.shippingAddress.otherRecipientName.$touch()"
                :valid="isValidOtherRecipientName"
                :error-message="$t('Please use only letters or symbols')
              "
              />
            </div>
          </template>
          <template v-if="isNewPost">
            <div class="o-order-step-shipping__np-title">
              {{ $t('Recipient title') }}
            </div>

            <div class="o-order-step-content__row">
              <SfInput
                key="npPhone"
                name="npPhone"
                v-model.trim="$v.shippingAddress.npPhone.$model"
                @blur="$v.shippingAddress.npPhone.$touch()"
                :required="true"
                :valid="!$v.shippingAddress.npPhone.$invalid"
                :error-message="!$v.shippingAddress.phone.required
                ? $t('Field is required')
                : $t('Please provide valid phone number')
              "
                v-mask="'+38##########'"
                :label="$t('Phone Number')"
                class="sf-input--filled o-order-step__input--width-100"
              />
            </div>

            <div class="o-order-step-content__row">
              <SfInput
                key="recipient"
                name="recipient"
                :label="$t('Surname of recipient')"
                :required="true"
                class="sf-input--filled o-order-step__input--width-100"
                v-model.trim="$v.shippingAddress.npLastname.$model"
                @blur="$v.shippingAddress.npLastname.$touch()"
                :valid="!$v.shippingAddress.npLastname.$error"
                :error-message="!$v.shippingAddress.npLastname.required
                    ? $t('Field is required')
                    : $t('Please use only letters or symbols cyr')
                  "
              />
            </div>

            <div class="o-order-step-content__row">
              <SfInput
                key="npFirstname"
                name="npFirstname"
                :label="$t('Name of recipient')"
                :required="true"
                class="sf-input--filled o-order-step__input--width-100"
                v-model.trim="$v.shippingAddress.npFirstname.$model"
                @blur="$v.shippingAddress.npFirstname.$touch()"
                :valid="!$v.shippingAddress.npFirstname.$error"
                :error-message="!$v.shippingAddress.npFirstname.required
                  ? $t('Field is required')
                  : $t('Please use only letters or symbols cyr')
                "
              />
            </div>

            <div class="o-order-step-content__row">
              <SfInput
                key="npMiddlename"
                name="npMiddlename"
                :label="$t('Your middlename')"
                :required="true"
                class="sf-input--filled o-order-step__input--width-100"
                v-model.trim="$v.shippingAddress.npMiddlename.$model"
                @blur="$v.shippingAddress.npMiddlename.$touch()"
                :valid="!$v.shippingAddress.npMiddlename.$error"
                :error-message="!$v.shippingAddress.npMiddlename.required
                    ? $t('Field is required')
                    : $t('Please use only letters or symbols cyr')
                  "
              />
            </div>
          </template>
          <div
            v-if="isDeliveryShippingMethod"
            class="o-order-step-content__end-m-24"
          >
            <button class="o-order-step-content__comment-button" @click="showComment = !showComment">
              {{ $t(showCommentButtonLabel) }}
            </button>
            <ASmoothReflow>
              <ATextarea
                v-if="showComment"
                :placeholder="$t('Comment to the collector')"
                v-model="shippingAddress.comment"
                id="package_comment"
                name="package-comment"
                class="a-textarea--filled o-order-step-content__top-m-10"
                :min-height="89"
                @clear="clearComment"
              />
            </ASmoothReflow>
          </div>
          <div class="o-order-step-content__row">
            <SfButton
              :disabled="isLoading"
              class="sf-button--primary sf-button--full-width-mobile form__action-button"
              data-transaction-name="Checkout - Shipping - Save"
              @click="onSaveButtonClick"
            >
              <template v-if="isFilled">
                {{ $t("Save changes") }}
              </template>
              <template v-else>
                {{ $t("Continue") }}
              </template>
            </SfButton>
          </div>
        </template>

        <template v-else-if="edited">
          <div
            class="o-order-step-content__error o-order-step-shipping__error"
          >{{ $t('shipping validation error') }}</div>

          <div class="o-order-step-content__row">
            <SfButton
              class="sf-button--primary sf-button--full-width-mobile form__action-button"
              data-transaction-name="Checkout - Shipping - Empty Set"
              @click="openModal"
            >
              {{ $t("Change shipping method") }}
            </SfButton>
          </div>
        </template>
        <template v-if="isFilled && !edited">
          <div
            v-if="shippingString.address"
            class="o-order-step-content__row o-order-step-shipping__current"
          >
            {{shippingString.type}}: {{ shippingString.address }}
          </div>
          <div
            v-if="otherRecipient"
            class="o-order-step-content__row short-description"
          >
            <div class="short-description__label">
              {{ $t('Recipient') }}:
            </div>
            <div class="short-description__value">
              <span>{{ otherRecipient }}</span>
            </div>
          </div>
        </template>

        <ALoadingSpinner v-if="isLoading" :is-absolute-position="true" />
      </ASmoothReflow>
    </div>
    <ASmoothReflow>
      <ACheckoutMessage
        v-if="isFilled && !edited && isNewPost"
        :message="$t('New post notice step')"
        variant="warning"
      />
    </ASmoothReflow>
  </div>
</template>

<script>
import {
  required
} from 'vuelidate/lib/validators'
import {
  isValidStringCharacter,
  isFirstCharNotZero,
  isValidPhoneNumber,
  isNameValidCyrillic
} from 'theme/helpers/validation'
import {
  SfButton,
  SfHeading,
  SfCheckbox, SfRadio
} from '@storefront-ui/vue'
import GoogleTagManager from 'theme/mixins/gtm'
import { mapActions, mapGetters, mapState } from 'vuex'
import config from 'config'
import { mask } from 'vue-the-mask'
import SfInput from 'theme/components/storefront-override/SfInput'
import { getCheckoutSessionItemByKey } from 'theme/store/checkout/helpers/session'
import Delivery from 'theme/mixins/Delivery';
import ASmoothReflow from 'theme/components/atoms/a-smooth-reflow.vue';
import ATextarea from 'theme/components/atoms/a-textarea.vue';
import { groups, sendToLogs } from 'theme/helpers/web-logging';
import ALoadingSpinner from 'theme/components/atoms/a-loading-spinner.vue';
import { codes, deliveryMethods, newPostMethods } from '$modules/shipping/config';
import { getCoords } from '$modules/shipping/helpers/shipping-helper';
import ACheckoutMessage from 'theme/components/atoms/a-checkout-message.vue';

export default {
  name: 'OOrderStepShipping',
  components: {
    ACheckoutMessage,
    ALoadingSpinner,
    ATextarea,
    SfRadio,
    ASmoothReflow,
    SfInput,
    SfButton,
    SfHeading,
    SfCheckbox
  },
  directives: {
    mask
  },
  mixins: [GoogleTagManager, Delivery],
  data () {
    return {
      showComment: false,
      isLoading: false,
      shippingAddress: {
        street: '',
        houseNumber: '',
        premisesType: 'FLAT',
        entranceNumber: '',
        flour: '',
        intercomCode: '',
        houseBuilding: '',
        officeNumber: '',
        companyName: '',
        city: '',
        postcode: '',
        phone: '+38',
        doorPostalHouseNumber: '',
        firstName: '',
        lastName: '',
        comment: '',
        latitude: '',
        longitude: '',
        isOtherRecipient: false,
        otherRecipientName: '',
        npFirstname: '',
        npLastname: '',
        npMiddlename: '',
        npPhone: ''
      },
      isFilled: false
    }
  },
  validations: {
    shippingAddress: {
      premisesType: {
        required
      },
      city: {
        required
      },
      street: {
        required
      },
      entranceNumber: {
        required,
        isValidStringCharacter,
        isFirstCharNotZero
      },
      flour: {
        required,
        isValidStringCharacter,
        isFirstCharNotZero
      },
      companyName: {
        required,
        isValidStringCharacter
      },
      doorPostalHouseNumber: {
        required,
        isValidStringCharacter,
        isFirstCharNotZero
      },
      intercomCode: {
        isValidStringCharacter
      },
      firstName: {
        required
      },
      lastName: {
        required
      },
      phone: {
        required,
        isValidPhoneNumber
      },
      otherRecipientName: {
        isValidStringCharacter
      },
      latitude: {
        required
      },
      longitude: {
        required
      },
      houseBuilding: {
        isValidStringCharacter,
        isFirstCharNotZero
      },
      officeNumber: {
        isValidStringCharacter,
        isFirstCharNotZero
      },
      npFirstname: {
        required,
        isNameValidCyrillic
      },
      npLastname: {
        required,
        isNameValidCyrillic
      },
      npMiddlename: {
        required,
        isNameValidCyrillic
      },
      npPhone: {
        required,
        isValidPhoneNumber
      }
    },
    houseValidationGroup: [
      'shippingAddress.premisesType',
      'shippingAddress.city',
      'shippingAddress.street',
      'shippingAddress.latitude',
      'shippingAddress.longitude'
    ],
    flatValidationGroup: [
      'shippingAddress.premisesType',
      'shippingAddress.city',
      'shippingAddress.street',
      'shippingAddress.entranceNumber',
      'shippingAddress.flour',
      'shippingAddress.doorPostalHouseNumber',
      'shippingAddress.latitude',
      'shippingAddress.longitude',
      'shippingAddress.intercomCode'
    ],
    officeValidationGroup: [
      'shippingAddress.premisesType',
      'shippingAddress.city',
      'shippingAddress.street',
      'shippingAddress.companyName',
      'shippingAddress.latitude',
      'shippingAddress.longitude',
      'shippingAddress.houseBuilding',
      'shippingAddress.officeNumber'
    ],
    recipientValidationGroup: [
      'shippingAddress.otherRecipientName',
      'shippingAddress.phone'
    ],
    npValidationGroup: [
      'shippingAddress.npPhone',
      'shippingAddress.npFirstname',
      'shippingAddress.npLastname',
      'shippingAddress.npMiddlename'
    ]
  },
  beforeMount () {
    this.$bus.$on('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$on('user-after-logout', this.resetCheckout)
  },
  beforeDestroy () {
    this.$bus.$off('checkout-after-load', this.onCheckoutLoad)
    this.$bus.$off('user-after-logout', this.resetCheckout)
  },
  props: {
    getActiveStep: {
      required: true,
      type: String,
      default: () => ''
    },
    cartStates: {
      required: true,
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user.current,
      getCurrentShipping: state => state['shipping-module'].current,
      getCurrentDraft: state => state['shipping-module'].draft
    }),
    ...mapGetters({
      userCoordinates: 'shipping/getUserCoordinates',
      productsInCart: 'cart/getCartItems',
      isDraftValid: 'shipping-module/isDraftValid'
    }),
    shipping () {
      const draft = this.getCurrentDraft

      if (draft.shop?.id) {
        return draft
      }

      return this.getCurrentShipping
    },
    otherRecipient () {
      if (!this.shippingAddress.isOtherRecipient) return

      return [
        this.shippingAddress.otherRecipientName,
        this.shippingAddress.phone
      ].filter(i => !!i).join(', ')
    },
    isDeliveryShippingMethod () {
      return deliveryMethods.includes(this.shipping?.method)
    },
    isNewPost () {
      return newPostMethods.includes(this.shipping?.method)
    },
    shippingString () {
      if (!this.isDefault && this.shipping?.method === codes.newPost) {
        const addressString = [
          this.shipping?.npCity?.settlement_description
            ? `${this.$t('c.')} ${this.shipping?.npCity?.settlement_description}` : '',
          this.shipping?.npShop?.label || ''
        ]
          .filter(i => !!i)
          .join(', ')

        return {
          address: addressString,
          type: this.$t(this.shipping.method.toLowerCase() || '')
        }
      }

      if (!this.isDefault && this.shipping?.shop?.id) {
        const address = this.shipping.address?.label || this.shipping?.shop?.address

        const addressString = this.isDeliveryShippingMethod
          ? (address || '')
          : [
            this.shipping?.city?.name || '',
            address || ''
          ]
            .filter(i => !!i)
            .join(', ')
        return {
          address: addressString,
          type: this.$t(this.shipping.method.toLowerCase() || '')
        }
      }

      return {
        address: '',
        type: ''
      }
    },
    premisesTypes () {
      return config.checkout.premisesTypes
    },
    getStreetAndHouseNumber () {
      if (this.shipping?.address?.address) {
        const {
          street,
          house
        } = this.shipping.address.address

        return {
          street,
          houseNumber: house
        }
      }

      const result = {
        street: '',
        houseNumber: ''
      }

      const address = this.shipping.address?.label || this.shipping?.shop?.address

      const streetParts = address?.split(',') || []

      if (streetParts.length) {
        const houseNumberString = streetParts[streetParts.length - 1]
        streetParts.splice(streetParts.length - 1, 1);

        result.street = streetParts.join(',');
        result.houseNumber = houseNumberString.replace(/[\s]/g, '');
      }

      return result
    },
    edited () {
      return this.getActiveStep === 'shipping'
    },
    isDefault () {
      return !this.edited && !this.isFilled
    },
    isValidForContinue () {
      if (
        this.isNewPost && this.$v.npValidationGroup.$invalid
      ) {
        return false
      }

      return this.productsInCart.length && (
        (
          !this.$v.shippingAddress.premisesType.$invalid &&
          !this.$v[this.shippingAddress.premisesType.toLowerCase() + 'ValidationGroup'].$invalid &&
          (!this.shippingAddress.isOtherRecipient || !this.$v.recipientValidationGroup.$invalid)
        ) || (
          !this.isDeliveryShippingMethod &&
          (
            !this.shippingAddress.isOtherRecipient ||
            !this.$v.recipientValidationGroup.$invalid
          )
        )
      )
    },
    isValidEntranceNumber () {
      return !this.$v.shippingAddress.entranceNumber.$error
    },
    isValidDoorPostalHouseNumber () {
      return !this.$v.shippingAddress.doorPostalHouseNumber.$error
    },
    isValidFlour () {
      return !this.$v.shippingAddress.flour.$error
    },
    isValidHouseBuilding () {
      return !this.$v.shippingAddress.houseBuilding.$error
    },
    isValidOfficeNumber () {
      return !this.$v.shippingAddress.officeNumber.$error
    },
    isValidCompanyName () {
      return !this.$v.shippingAddress.companyName.$error
    },
    isValidPhone () {
      return !this.$v.shippingAddress.phone.$error
    },
    isValidOtherRecipientName () {
      return !this.$v.shippingAddress.otherRecipientName.$error
    },
    showCommentButtonLabel () {
      return this.showComment ? 'Hide a comment to the courier' : 'Add a comment to the courier'
    }
  },
  methods: {
    ...mapActions({
      saveShipping: 'shipping-module/saveShipping'
    }),
    getUserShippingData () {
      const [longitude, latitude] = getCoords(this.getCurrentShipping)

      return {
        firstName: this.currentUser?.firstname || '',
        lastName: this.currentUser?.lastname || '',
        latitude: latitude || this.userCoordinates.lat,
        longitude: longitude || this.userCoordinates.lng,
        npFirstname: this.shippingAddress?.npFirstname || this.currentUser?.firstname || '',
        npLastname: this.shippingAddress?.npLastname || this.currentUser?.lastname || '',
        npPhone: this.shippingAddress?.npPhone || this.currentUser?.phone || ''
      }
    },
    sendDataToCheckout (onCheckoutLoad = false) {
      this.isFilled = true

      this.$bus.$emit('checkout-after-shippingDetails', { data: this.shippingAddress, onCheckoutLoad }, this.isValidForContinue)
    },
    async onSaveButtonClick () {
      try {
        this.isLoading = true

        if (!this.isValidForContinue) {
          this.$v.$touch();
          return
        }

        if (this.getCurrentDraft?.shop?.id && !this.isDraftValid) {
          return this.openModal()
        }

        if (this.getCurrentDraft?.shop?.id) {
          await this.saveShipping()
        }

        this.sendDataToCheckout()
      } catch (e) {
        sendToLogs(
          groups.Checkout,
          'checkout:shipping:save:error',
          { message: e.message }
        )
      } finally {
        this.isLoading = false
      }
    },
    openModal () {
      this.$bus.$emit('checkout-filled-clear', 'shipping')
      this.openShippingModal({ draftMode: true })
    },
    edit () {
      this.$bus.$emit('checkout-before-edit', 'shipping')
    },
    onCheckoutLoad () {
      const sessionData = getCheckoutSessionItemByKey('shippingDetails')

      if (sessionData) {
        this.shippingAddress = sessionData
        this.sendDataToCheckout(true)
      }
    },
    clearComment () {
      this.shippingAddress.comment = '';
    },
    resetCheckout () {
      this.isFilled = false
    },
    getErrorMessage (field = '') {
      if (!field) return ''

      let message = ''
      const required = this.$v.shippingAddress[field].required === undefined ? true
        : this.$v.shippingAddress[field].required
      const isValidStringCharacter = this.$v.shippingAddress[field].isValidStringCharacter === undefined ? true
        : this.$v.shippingAddress[field].isValidStringCharacter
      const isFirstCharNotZero = this.$v.shippingAddress[field].isFirstCharNotZero === undefined ? true
        : this.$v.shippingAddress[field].isFirstCharNotZero

      if (!required) message = this.$t('Field is required')
      else if (!isValidStringCharacter) message = this.$t('Please use only letters, digits or symbols')
      else if (!isFirstCharNotZero) message = this.$t('Value can not be zero')

      return message
    }
  },
  watch: {
    isNewPost: {
      immediate: true,
      handler (val) {
        if (!val) return

        this.shippingAddress.isOtherRecipient = false
      }
    },
    currentUser: {
      handler: function () {
        if (this.shippingAddress.isOtherRecipient) {
          return
        }

        this.shippingAddress = Object.assign(this.shippingAddress, this.getUserShippingData())
      },
      deep: true
    },
    shipping: {
      immediate: true,
      handler: function (val) {
        if (!val?.shop?.id) return

        this.shippingAddress = Object.assign(
          this.shippingAddress,
          {
            city: this.shipping?.city?.name || this.shipping?.npCity?.name || '',
            street: this.getStreetAndHouseNumber.street,
            houseNumber: this.getStreetAndHouseNumber.houseNumber,
            country: 'UA',
            postcode: '' // TODO where is it ???
          },
          this.getUserShippingData()
        )
      }
    },
    isValidForContinue (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('update-shipping-validation-result', newValue)
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/components/organisms/o-order-step";

.o-order-step-shipping {
  &__content {
    position: relative;
  }

  &__np-title {
    font-weight: bold;
    padding-bottom: var(--spacer-10);
  }

  &__error {
    padding-bottom: var(--spacer-16);
  }

  ::v-deep .sf-input__error-message {
    height: unset;
    max-height:  var(--spacer-30);
  }

  &__label {
    display: block;
    width: 100%;
    color: var(--dark-gray);
    margin-bottom: var(--spacer-10)
  }

  &--full-width {
    max-width: 100%;
  }

  &__current {
    flex-direction: column;
  }

  &__notification {
    --info-block-background: var(--pale-orange);
    margin-bottom: var(--spacer-20);
  }

  &__checkbox {
    --checkbox-label-margin: 0 0 0 var(--spacer-14);
    --checkbox-font-line-height: var(--font-size-17);
    --checkbox-font-size: var(--font-size-14);
    --checkbox-font-weight: normal;
    --checkbox-label-color: var(--black);
    --icon-width: var(--font-size-12);
    --icon-height: var(--font-size-12);
  }
}
.row__recipient {
  margin: 0 0 var(--spacer-20);

  &--true {
    margin: 0 0 var(--spacer-15);
  }
}

.short-description {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  &__label {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--dark-gray);
    padding: 0 var(--spacer-5) 0 0;
    box-sizing: border-box;
  }

  &__value {
    font-family: var(--font-family-secondary);
    font-size: var(--font-size-14);
    font-weight: var(--font-normal);
    line-height: var(--font-size-17);
    color: var(--black);
    padding: 0 var(--spacer-10) 0 0;
    box-sizing: border-box;

    span {
      white-space: nowrap;
    }
  }
}

.button--disabled {
  --button-background: var(--orange-disabled);
  --button-color: var(--white);
}
</style>
